do ($ = jQuery) ->
  $ ->
    $nav_clone = ($ '.view-id-search_type .item-list').clone()
    $nav_clone.appendTo('.attachment-after')

    setInterval ->
      wrapper = document.querySelector "#block-block-7 .content > ul"

      if not wrapper
        return

      left = wrapper.getBoundingClientRect().left + window.scrollX
      xs = document.querySelectorAll "#block-block-7 .content > ul > li"

      if not xs
        return

      n = xs.length
      x.classList.remove 'teleport' for x in xs
      x.dataset.offset = -1 + (parseInt x.dataset.offset ? 0) for x in xs
      (x.dataset.offset = (n - 2 - i) if x.getBoundingClientRect().left - left < 0) for x, i in xs
      (x.classList.add 'teleport' if x.getBoundingClientRect().left - left < 0) for x in xs
      x.style.transform = "translateX("+(100*parseInt x.dataset.offset)+"%)" for x in xs
    , 3000
